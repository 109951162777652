import { request } from '@/utils/request'
export const selectCitymixins = {
    data() {
        return {
            prov: [],
            city: [],
            area: [],
        };
    },
    computed: {},
    created() {

    },
    mounted() {
        // 省市区
        this.getCity();
    },
    methods: {
        getCity() {
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
                if (res.data.code == 200) {
                    this.prov = res.data.data;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        provChange(id) {
            this.city = []
            this.area = []
            delete this.queryData.cityCode;// 清空市的选择
            delete this.queryData.countyCode; // 清空区/县的选择
            if (!id) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        cityChange(id) {
            if (!id) {
                return false;
            }
            this.area = []
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.area = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        areaChange(id) {
            this.countyCode = id;
        },
    },
};

