<template>
    <div>
        <a-card style="background-color: #fff;" v-if="hasPermission('QUERY_SHOP')">
            <div :class="advanced ? 'search' : null">
                <a-form layout="horizontal">
                    <div :class="advanced ? null : 'fold'">
                        <a-row>
                            <a-col :md="6" :sm="24" style="max-width: 400px;">
                                <a-form-item label="门店名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-input v-model="queryData.name" placeholder="请输入门店名称" />
                                </a-form-item>
                            </a-col>
<!--                            <a-col :md="6" :sm="24" style="max-width: 400px;">-->
<!--                                <a-form-item label="门店类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">-->
<!--                                    <a-select allowClear v-model="queryData.type" placeholder="请选择门店类型"-->
<!--                                        option-label-prop="label">-->
<!--                                        <a-select-option value="1" label="直营">直营</a-select-option>-->
<!--                                        <a-select-option value="2" label="加盟">加盟</a-select-option>-->
<!--                                    </a-select>-->
<!--                                </a-form-item>-->
<!--                            </a-col>-->
                            <a-col :md="6" :sm="24" style="max-width: 400px;">
                                <a-form-item label="状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.status" placeholder="请选择状态"
                                        option-label-prop="label">
                                        <a-select-option value="0" label="筹建中">筹建中</a-select-option>
                                        <a-select-option value="1" label="营业中">营业中</a-select-option>
                                        <a-select-option value="2" label="暂停营业">暂停营业</a-select-option>
                                        <a-select-option value="3" label="永久关门">永久关门</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="6" :sm="24" style="max-width: 400px;">
                                <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-range-picker :value="dateValue" :ranges="{
                                        '今天': [moment(), moment()],
                                        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                      '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    }" @change="dateChange" />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :md="6" :sm="24" style="max-width: 400px;">
                                <a-form-item label="地址" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <div style="display: flex;margin-top: 6px;">
                                        <a-select allowClear @change="provChange" v-model="queryData.provinceCode"
                                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                                            show-search placeholder="省" option-label-prop="label">
                                            <a-select-option v-for="item in prov" :key="item.id" :value="item.id"
                                                :label="item.name">{{ item.name }}</a-select-option>
                                        </a-select>
                                        <a-select @change="cityChange" allowClear v-model="queryData.cityCode"
                                            placeholder="市"
                                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                                            show-search option-label-prop="label">
                                            <a-select-option v-for="item in city" :key="item.id" :value="item.id"
                                                :label="item.name">{{ item.name }}</a-select-option>
                                        </a-select>
                                        <a-select @change="areaChange" allowClear v-model="queryData.countyCode"
                                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                                            show-search placeholder="区/县" option-label-prop="label">
                                            <a-select-option v-for="item in area" :key="item.id" :value="item.id"
                                                :label="item.name">{{ item.name }}</a-select-option>
                                        </a-select>
                                    </div>
                                </a-form-item>
                            </a-col>
                            <a-col :md="6" :sm="24" style="max-width: 400px;">
                                <a-form-item label="类目" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.pdtCatId" placeholder="请选择类目"
                                    @change="pdtChange"
                                        option-label-prop="label">
                                        <a-select-option v-for="item in pdtCatList" :value="item.id" :label="item.name" :key="item.id">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="6" :sm="24" style="max-width: 400px;">
                                <a-form-item label="品牌" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.scmBrandId" placeholder="请选择品牌" show-search
                                        :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" :label="item.name" v-for="item in brandList"
                                            :key="item.id">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                     
                            <a-col :md="6" :sm="24">
                                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    @click="search">查询</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    @click="exportTask" v-if="hasPermission('EXPORT_SHOP')">导出</a-button>
                            </a-col>
                        </a-row>
                        <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total>0 ? 1 : 0 }} 至 {{ queryData.size > total ?  total : queryData.size }} 项结果，共 {{ total }} 项</div>
                    </div>

                </a-form>

            </div>


            <div>
                <a-table :columns="columns" bordered :scroll="{x:1500}" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                    <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
                    <template slot="discount" slot-scope="text">{{ text * 100 }}</template>

                    <template slot="status" slot-scope="text">
                        <span v-if="text == 0">筹建中</span>
                        <span v-if="text == 1">营业中</span>
                        <span v-if="text == 2">暂停营业</span>
                        <span v-if="text == 3">永久关停</span>
                    </template>
                    <template slot="type" slot-scope="text">
                        <span v-if="text == 1">直营</span>
                        <span v-if="text == 2">加盟</span>
                    </template>

                    <template slot="address" slot-scope="text">
                        <a-popover>
                            <template slot="content">
                                {{ text }}
                            </template>
                            {{ text }}
                        </a-popover>
                    </template>
                    <template slot="icon" slot-scope="text">
                      <img style="width: 60px;height: 60px;" :src="text" alt="">
                    </template>

                </a-table>
                <div class="page-box">
                    <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                        :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                        show-size-changer />
                </div>


            </div>

        </a-card>

        <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
            <a-empty :description="false" />
            无权限查看
        </div>
    </div>
</template>
  
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { selectCitymixins } from '@/mixin/selectCity'
import { publicMixin } from '@/mixin/public'

const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80, },
    
    {
        title: '品牌',
        dataIndex: 'scmBrandName',
        // width: 140,
        align: 'center'
    },
    {
        title: '类目',
        dataIndex: 'pdtCat',
        align: 'center'
    },
    {
        title: '门店名称',
        dataIndex: 'name',
        // width: 140,
        align: 'center'
    },
    {
        title: 'icon',
        dataIndex: 'icon',
        scopedSlots: { customRender: 'icon' },
        width: 120,
        align: 'center'
    },
    {
        title: '省份',
        dataIndex: 'provinceName',
        // width: 140,
        align: 'center'
    },
    {
        title: '城市',
        dataIndex: 'cityName',
        // width: 140, 
        align: 'center'
    },
    {
        title: '区',
        dataIndex: 'countyName',
        // width: 140,
        align: 'center'
    },
    {
        title: '地址详情',
        dataIndex: 'address',
        scopedSlots: { customRender: 'address' },
        ellipsis: true,
        width: 200,
        align: 'center'
    },
    // {
    //     title: '门店类型',
    //     dataIndex: 'type',
    //     // width: 140,
    //     scopedSlots: { customRender: 'type' },
    //     align: 'center'
    // },

    {
        title: '门店状态',
        // width: 140,
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
        align: 'center'
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        // width: 140,
        scopedSlots: { customRender: 'createTime' },
        align: 'center'
    }
]

export default {
    name: 'QueryList',
    mixins: [selectCitymixins,publicMixin],
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],

            // 筛选
            queryData: {
                page: 0,
                size: 10,
                name: ''
            },

            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            downCurrent: 1,
            downTotal: 0,
            tabTotal: 0,
            currentPage: 1,
            total: 0,
            brandList: [],
            pdtCatList:[]

        }
    },
    authorize: {
        deleteRecord: 'delete'
    },
    mounted() {
        // 列表
        this.getData();
        // 品牌
        this.getBrand();
        // 类目
        this.getNormalpdtcat();
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        }
    },
    methods: {
        search(){
            this.queryData.page = 0;
            this.getData();
        },
        pdtChange(e) {
            delete this.queryData.scmBrandId
            this.getBrand(e)
        },
        getBrand(id) {
           let url = 'sfa/scm/brand/list/normal'
            request(process.env.VUE_APP_API_BASE_URL +url, 'post',{pdtCatId:id}).then(res => {
                if (res.data.code == 200) {
                    this.brandList = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },        
        getNormalpdtcat() {
           request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/brand/list/normalpdtcat', 'get').then(res => {
               if (res.data.code == 200) {
                   this.pdtCatList = res.data.data;
               } else {
                   this.$message.error(res.data.message);
               }
           })
       },  
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/shop/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/shop/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },

        deleteRecord(key) {
            this.dataSource = this.dataSource.filter(item => item.key !== key)
            this.selectedRows = this.selectedRows.filter(item => item.key !== key)
        },
        toggleAdvanced() {
            this.advanced = !this.advanced
        },

    }
}
</script>
  
<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>
  